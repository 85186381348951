import * as _getIntrinsic2 from "get-intrinsic";
var _getIntrinsic = _getIntrinsic2;
try {
  if ("default" in _getIntrinsic2) _getIntrinsic = _getIntrinsic2.default;
} catch (e) {}
import * as _callBound2 from "call-bound";
var _callBound = _callBound2;
try {
  if ("default" in _callBound2) _callBound = _callBound2.default;
} catch (e) {}
import * as _objectInspect2 from "object-inspect";
var _objectInspect = _objectInspect2;
try {
  if ("default" in _objectInspect2) _objectInspect = _objectInspect2.default;
} catch (e) {}
import * as _type2 from "es-errors/type";
var _type = _type2;
try {
  if ("default" in _type2) _type = _type2.default;
} catch (e) {}
var exports = {};
var GetIntrinsic = _getIntrinsic;
var callBound = _callBound;
var inspect = _objectInspect;
var $TypeError = _type;
var $Map = GetIntrinsic("%Map%", true);

/** @type {<K, V>(thisArg: Map<K, V>, key: K) => V} */
var $mapGet = callBound("Map.prototype.get", true);
/** @type {<K, V>(thisArg: Map<K, V>, key: K, value: V) => void} */
var $mapSet = callBound("Map.prototype.set", true);
/** @type {<K, V>(thisArg: Map<K, V>, key: K) => boolean} */
var $mapHas = callBound("Map.prototype.has", true);
/** @type {<K, V>(thisArg: Map<K, V>, key: K) => boolean} */
var $mapDelete = callBound("Map.prototype.delete", true);
/** @type {<K, V>(thisArg: Map<K, V>) => number} */
var $mapSize = callBound("Map.prototype.size", true);

/** @type {import('.')} */
exports = !!$Map && /** @type {Exclude<import('.'), false>} */function getSideChannelMap() {
  /** @typedef {ReturnType<typeof getSideChannelMap>} Channel */
  /** @typedef {Parameters<Channel['get']>[0]} K */
  /** @typedef {Parameters<Channel['set']>[1]} V */

  /** @type {Map<K, V> | undefined} */var $m;

  /** @type {Channel} */
  var channel = {
    assert: function (key) {
      if (!channel.has(key)) {
        throw new $TypeError("Side channel does not contain " + inspect(key));
      }
    },
    "delete": function (key) {
      if ($m) {
        var result = $mapDelete($m, key);
        if ($mapSize($m) === 0) {
          $m = void undefined;
        }
        return result;
      }
      return false;
    },
    get: function (key) {
      // eslint-disable-line consistent-return
      if ($m) {
        return $mapGet($m, key);
      }
    },
    has: function (key) {
      if ($m) {
        return $mapHas($m, key);
      }
      return false;
    },
    set: function (key, value) {
      if (!$m) {
        // @ts-expect-error TS can't handle narrowing a variable inside a closure
        $m = new $Map();
      }
      $mapSet($m, key, value);
    }
  };

  // @ts-expect-error TODO: figure out why TS is erroring here
  return channel;
};
export default exports;